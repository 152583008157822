// --- External tools
import axios from 'axios';

// --- Logic
import { commonConfiguration } from 'io/API/LoggingAPI';
import { onRequestError } from 'io/API/errorHandling';


const postLogEntry = (userID, logData, contextConfiguration) => axios.post(
	'/log',
	{
		log: userID,
		...logData
	},
	{
		...commonConfiguration,
		...contextConfiguration,
	}
).catch(onRequestError);

export default postLogEntry;
